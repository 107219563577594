// General styles
@import "variables";
.greyHeader {
    width: 100%;
    text-align: center;
    & + .main-col {
        padding-top: 0;
    }
}
.product {
    h2 {
        p {
            color: white !important;
            font-size: 18px;
            line-height: 1;
            padding: 0;
            margin: 0;
        }
    }
    img {
        border-radius: 80px;
        margin-left: 50%;
        margin-top: 30%;
        transform: translate(-50%, -30%);
    }
    .section {
        border-bottom: solid 1px $brand-gray-light;
        padding-top: 10px;
        &:last-child {
            border-bottom: none;
        }
        p {
            padding-top: 5px;
        }
    }
}
