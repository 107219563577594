

// Default colors:
// -----------------------------------------------------------------------------

$brand-blue:                      #140043 !default;
$brand-blue-light:                #D8F2FF !default;
$brand-orange-darker:             #84b00d !default;
$brand-orange-dark:               #95c11e !default;
$brand-orange:                    #95c11e !default;
$brand-orange-global:             #fb7100 !default;
$brand-gray-darker:               #4b4b4b !default;
$brand-gray-dark:                 #666666 !default;
$brand-gray:                      #cccccc !default;
$brand-gray-light:                #e5e5e5 !default;
$brand-gray-lighter:              #f8f8f8 !default;
$brand-aubergine:                 #6f5e61 !default;

$deepBlue:                  #183e75;

$light:								#ffffff;
$formback:							#eeeeee;

body {
    background-color: $brand-gray-lighter;
}
